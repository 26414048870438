import { Table, Text } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';

import { stateData } from './rules';

type TaxRulesInstance = {
  // stateId: string;
  state: string;
  baseRate: string;
  avgRate: string;
  localRate: string;
  saas: string;
  physical: string;
  service: string;
  dollarThreshold: string;
  transactionThreshold: string;
  // physicalNexus: string;
};

const TABLE_HEAD = [
  'JURISDICTION',
  'BASE RATE',
  'AVG. COMBINED RATE',
  'LOCAL TAX RATE',
  'SAAS TAXABLE',
  'PHYSICAL TAXABLE',
  'SERVICE TAXABLE',
  'SALES THRESHOLD',
  'TRANSACTION THRESHOLD',
];

const TaxRules = () => {
  return (
    <KDataTable headers={TABLE_HEAD}>
      {stateData?.map(row => <TaxRulesTableRow key={row.stateId} {...row} />)}
    </KDataTable>
  );
};

const TaxRulesTableRow = (props: TaxRulesInstance) => {
  const { state, baseRate, avgRate, localRate, saas, physical, service, dollarThreshold, transactionThreshold } = props;

  return (
    <Table.Row
      _hover={{
        bg: '#EFEFF3',
      }}
    >
      <Table.Cell>
        <Text>{state}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{baseRate}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{avgRate}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{localRate}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{saas}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{physical}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{service}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{dollarThreshold}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{transactionThreshold}</Text>
      </Table.Cell>
    </Table.Row>
  );
};

export default TaxRules;
