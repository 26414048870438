import { Stack, Text } from '@chakra-ui/react';
import { AddressCard } from 'components/Address/AddressCard';
import { INITIAL_ADDRESS_DATA } from 'constants/address';
import { useState } from 'react';
import { AddressResSchema } from 'types/address';
import { AddressType } from 'types/shared-types';

import CalculatorForm from './CalculatorForm';

const TaxCalculator = () => {
  const [address, setAddress] = useState<AddressResSchema[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressResSchema>(INITIAL_ADDRESS_DATA);

  const [addressFormOpen, setAddressFormOpen] = useState(false);

  const openAddOrEditForm = (type: AddressType) => {
    if (type === AddressType.BILL_TO) {
      setAddressFormOpen(true);
      const billTo = address?.find(a => a.type === AddressType.BILL_TO);
      setSelectedAddress(billTo ?? INITIAL_ADDRESS_DATA);
    }
    if (type === AddressType.SHIP_TO) {
      setAddressFormOpen(true);
      const shipTo = address?.find(a => a.type === AddressType.SHIP_TO);
      setSelectedAddress(shipTo ?? { ...INITIAL_ADDRESS_DATA, type: AddressType.SHIP_TO });
    }
  };

  const handleOnSubmitAddress = (value: AddressResSchema) => {
    const addressValues = [...address];
    const index = addressValues?.findIndex(a => a.type === value.type);
    if (index !== -1) {
      addressValues.splice(index, 1, value);
    } else {
      addressValues.push(value);
    }
    setAddress(addressValues);
    onCloseAddressModal();
  };

  const onCloseAddressModal = () => {
    setAddressFormOpen(false);
  };

  return (
    <>
      <Text fontSize="sm" color={'primary'}>
        This tool estimates sales tax based on the Nexus, the entered addresses, and transaction items. It returns zero
        if the Nexus doesn&apos;t exist. Start by entering addresses, preferably the &apos;Ship To&apos; address.
      </Text>
      <Stack flexDirection={{ base: 'column', md: 'row' }} gap={6} mt={6}>
        <AddressCard
          size="lg"
          key={AddressType.BILL_TO}
          title="Bill To"
          address={address?.find(a => a.type === AddressType.BILL_TO) || undefined}
          onClickEdit={() => openAddOrEditForm(AddressType.BILL_TO)}
        />
        <AddressCard
          size="lg"
          key={AddressType.SHIP_TO}
          title="Ship To"
          address={address?.find(a => a.type === AddressType.SHIP_TO) || undefined}
          onClickEdit={() => openAddOrEditForm(AddressType.SHIP_TO)}
        />
      </Stack>
      <CalculatorForm
        selectedAddress={selectedAddress}
        addressFormOpen={addressFormOpen}
        onCloseAddressModal={onCloseAddressModal}
        handleOnSubmitAddress={handleOnSubmitAddress}
      />
    </>
  );
};

export default TaxCalculator;
