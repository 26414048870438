import { Tabs } from '@chakra-ui/react';
import AppHeader from 'app/app-header';

import TaxCalculator from './components/TaxCalculator';
import TaxRules from './components/TaxRules';

const ToolsAndGuide = () => {
  return (
    <>
      <AppHeader />
      <Tabs.Root colorPalette="blue" defaultValue="rules">
        <Tabs.List>
          <Tabs.Trigger _selected={{ color: 'secondary.500' }} value="rules">
            Tax Rules
          </Tabs.Trigger>
          <Tabs.Trigger _selected={{ color: 'secondary.500' }} value="calculator">
            Tax Calculator
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="rules">
          <TaxRules />
        </Tabs.Content>
        <Tabs.Content value="calculator">
          <TaxCalculator />
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};

export default ToolsAndGuide;
