import { TransactionEstimateRequest } from '_api-client';
import { Box, HStack, Icon, Input, StackSeparator, Text, VStack } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProducts, PRODUCT_STATE_KEY } from 'apis/product-apis';
import { calculateTaxEstimate } from 'apis/tax-estimate';
import { AddressFormModal } from 'components/Address/AddressFormModal';
import { DeleteIcon } from 'components/icons';
import { KCustomSelect } from 'components/k-custom-select/k-custom-select';
import { Button } from 'components/ui/button';
import { Field } from 'components/ui/field';
import { Tooltip } from 'components/ui/tooltip';
import { formatISO } from 'date-fns';
import { FieldArray, Form, Formik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';
import { useState } from 'react';
import { CurrencyEnum } from 'schema/types-schema.d';
import { AddressResSchema } from 'types/address';
import { ProductInstance, ProductStatusEnum } from 'types/shared-types';
import {
  ProductDropdownItemType,
  TaxEstimateValidationSchema,
  TaxEstimateValidationSchemaType,
  TotalDataType,
} from 'types/tax-estimates';
import { NumberStyleEnum } from 'types/utils';
import { generateRandomNumber, replaceNullWithEmptyString } from 'utils';
import { formatCurrency, formatNumber } from 'utils/utils';

import CalculatorTotalSection from './CalculatorTotalSection';

const initialTransactionItem = {
  external_product_id: '',
  product_description: '',
  quantity: '',
  tax_amount: '',
  amount: '',
  date: formatISO(new Date()),
  tax_items: [],
};

const CalculatorForm = ({
  selectedAddress,
  addressFormOpen,
  onCloseAddressModal,
  handleOnSubmitAddress,
}: {
  selectedAddress: AddressResSchema;
  addressFormOpen: boolean;
  onCloseAddressModal: () => void;
  handleOnSubmitAddress: (address: AddressResSchema) => void;
}) => {
  const { orgId } = useOrg();
  const { handleFailNotification } = useHandleNotification();
  const { status__in } = useTableFilters({
    status__in: [ProductStatusEnum.APPROVED, ProductStatusEnum.PARTIALLY_APPROVED],
  });
  const [showTotalSection, setShowTotalSection] = useState(false);
  const [loadSavedData, setLoadSavedData] = useState<TransactionEstimateRequest | null>(null);
  const [taxEstimateTotal, setTaxEstimateTotal] = useState<TotalDataType | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const formInitialValues = {
    transaction_items: [initialTransactionItem],
    addresses: [],
  };

  const { data: products, isPending: isLoading } = useQuery<ProductDropdownItemType[] | []>({
    queryKey: [PRODUCT_STATE_KEY, orgId, status__in, searchQuery],
    queryFn: async () => {
      const res = await getProducts({
        orgId,
        params: {
          status__in,
          query: searchQuery,
        },
      });
      if (res.data?.items.length > 0) {
        const prodDropdownItem = res.data.items?.map((product: ProductInstance) => {
          return {
            value: product.external_id,
            label: product.name,
            product_description: product.description,
            product_source: product.source,
          };
        });
        return prodDropdownItem;
      }
      return [];
    },
  });

  const { mutate: calculateTaxEstimates, isPending: isSubmitLoading } = useMutation({
    mutationFn: (payload: TransactionEstimateRequest) => {
      return calculateTaxEstimate(orgId, payload).then(response => {
        const { data } = response;
        const {
          source,
          external_id,
          date,
          total_amount,
          currency,
          total_tax_amount_calculated,
          addresses,
          transaction_items,
        } = data;
        const formatToFormData = {
          source,
          external_id,
          date,
          total_amount,
          currency,
          addresses: replaceNullWithEmptyString(addresses),
          transaction_items,
        } as TransactionEstimateRequest;

        const taxEstimateTotalData = {
          total_amount,
          total_tax_amount_calculated,
        } as TotalDataType;
        setLoadSavedData(formatToFormData);
        setTaxEstimateTotal(taxEstimateTotalData);
      });
    },
    onSuccess: () => {
      setShowTotalSection(true);
    },
    onError: (error: any) => {
      handleFailNotification(error);
      setShowTotalSection(false);
    },
  });

  const handleTotalSectionVisible = (removedProdExtId: string) => {
    const itemIndex = loadSavedData?.transaction_items.findIndex(
      ({ external_product_id }) => external_product_id === removedProdExtId
    );
    if (itemIndex === 1) {
      setShowTotalSection(false);
    } else setShowTotalSection(true);
  };

  const handleFormSubmit = (values: TransactionEstimateRequest | TaxEstimateValidationSchemaType) => {
    const payload: TransactionEstimateRequest = {
      ...values,
      external_id: generateRandomNumber().toString(), // random number for external_id, as actual transaction did not happened yet, BE need that and it will not be saved
      date: new Date().toISOString(),
      total_amount: values.transaction_items.reduce((acc: number, item: any) => acc + Number(item.amount), 0),
      currency: CurrencyEnum.USD,
      transaction_items: values.transaction_items.map((item: any) => ({
        ...item,
        product_name: products?.filter(({ value }) => value === item.external_product_id)?.[0]?.label,
        product_source: products?.filter(({ value }) => value === item.external_product_id)?.[0]?.product_source,
        quantity: Number(item.quantity),
        amount: Number(item.amount),
      })),
    };

    calculateTaxEstimates(payload);
  };

  return (
    <Formik
      initialValues={loadSavedData || formInitialValues}
      enableReinitialize
      validationSchema={TaxEstimateValidationSchema}
      onSubmit={values => {
        handleFormSubmit(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, isValid, dirty, errors }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <FieldArray name="transaction_items">
              {({
                insert,
                remove,
                form: {
                  values: { transaction_items },
                  handleChange,
                },
              }) => (
                <>
                  <Box border={'1px solid '} borderColor={'#EFEFF3'} p={4} mt={4} width={'full'}>
                    {values.transaction_items.length > 0 &&
                      values.transaction_items.map((_, index) => (
                        <>
                          {index > 0 && <StackSeparator mt={4} borderWidth={'1px'} borderColor="#EFEFF3" mb={4} />}
                          <HStack
                            align={'baseline'}
                            key={index}
                            gap={4}
                            flexWrap={{ base: 'nowrap', md: 'nowrap', sm: 'wrap' }}
                          >
                            <Field
                              label="Product"
                              invalid={!!(errors.transaction_items?.[index] as any)?.external_product_id}
                              errorText={(errors.transaction_items?.[index] as any)?.external_product_id}
                              required
                            >
                              <KCustomSelect
                                id={`transaction_items.${index}.external_product_id`}
                                name={`transaction_items.${index}.external_product_id`}
                                enableSearch
                                searchPlaceholder="Search product"
                                isDataLoading={isLoading}
                                searchQuery={searchQuery}
                                onChangeSearchQuery={setSearchQuery}
                                items={products || []}
                                onValueChange={({ value }) => {
                                  setFieldValue(`transaction_items.${index}.external_product_id`, value[0]);
                                  const productDesc = products?.filter(
                                    (product: any) => product.value === value[0]
                                  )?.[0]?.product_description;
                                  setFieldValue(`transaction_items.${index}.product_description`, productDesc ?? '');
                                  setShowTotalSection(false);
                                }}
                                disabled={isLoading}
                              />
                            </Field>

                            <Field label="Description">
                              <Text pt={1} lineClamp={2}>
                                {transaction_items[index].product_description}
                              </Text>
                            </Field>

                            <Field
                              label="Quantity"
                              invalid={!!(errors.transaction_items?.[index] as any)?.quantity}
                              errorText={(errors.transaction_items?.[index] as any)?.quantity}
                              required
                            >
                              <Input
                                inputMode="numeric"
                                maxLength={10}
                                id="quantity"
                                placeholder={'Enter Quantity'}
                                name={`transaction_items.${index}.quantity`}
                                value={transaction_items[index].quantity}
                                onChange={evt => {
                                  handleChange(evt);
                                  setShowTotalSection(false);
                                }}
                              />
                            </Field>

                            <Field label="Tax">
                              <Text pt={1}>
                                {transaction_items[index].tax_amount > 0 &&
                                transaction_items[index]?.tax_items.length > 0 ? (
                                  <Tooltip
                                    content={transaction_items[index]?.tax_items.map((item: any, index: number) => (
                                      <>
                                        <HStack p={'8px'}>
                                          <Box w={'105px'}>
                                            <Text color={'white'} fontWeight="medium">
                                              {item.name}
                                            </Text>
                                          </Box>
                                          <Box minW={'60px'} mr={'30px'}>
                                            <Text color={'white'}>
                                              {formatNumber(item.rate, { style: NumberStyleEnum.PERCENT })}
                                            </Text>
                                          </Box>
                                          <Box minW={'70px'}>
                                            <Text color={'white'}> {formatCurrency(item.amount)}</Text>
                                          </Box>
                                        </HStack>
                                        {index < transaction_items[index]?.tax_items.length - 1 && (
                                          <hr style={{ borderTop: '1px solid #383D58' }} />
                                        )}
                                      </>
                                    ))}
                                  >
                                    <Text>{formatCurrency(transaction_items[index]?.tax_amount)}</Text>
                                  </Tooltip>
                                ) : (
                                  <Text>{formatCurrency(transaction_items[index]?.tax_amount)}</Text>
                                )}
                              </Text>
                            </Field>

                            <Field
                              label="Amount"
                              invalid={!!(errors.transaction_items?.[index] as any)?.amount}
                              errorText={(errors.transaction_items?.[index] as any)?.amount}
                              required
                            >
                              <Input
                                inputMode="numeric"
                                id="amount"
                                maxLength={10}
                                placeholder={'Enter Amount'}
                                name={`transaction_items.${index}.amount`}
                                value={transaction_items[index].amount}
                                onChange={evt => {
                                  handleChange(evt);
                                  setShowTotalSection(false);
                                }}
                                textAlign={'right'}
                                _placeholder={{ textAlign: 'left' }}
                              />
                            </Field>

                            <VStack minW={'20px'} gap={4}>
                              <Box />
                              {index > 0 && (
                                <Icon
                                  verticalAlign={'middle'}
                                  width={5}
                                  height={5}
                                  onClick={() => {
                                    remove(index);
                                    handleTotalSectionVisible(transaction_items[index].external_product_id);
                                  }}
                                  color={'#E53E3E'}
                                  cursor={'pointer'}
                                >
                                  <DeleteIcon />
                                </Icon>
                              )}
                            </VStack>
                          </HStack>
                        </>
                      ))}
                  </Box>

                  <HStack mt={6} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        insert(values.transaction_items.length + 1, initialTransactionItem);
                        setShowTotalSection(false);
                      }}
                    >
                      + Add Product
                    </Button>

                    {isValid && showTotalSection ? (
                      <CalculatorTotalSection
                        subTotal={taxEstimateTotal?.total_amount ?? 0}
                        taxAmount={taxEstimateTotal?.total_tax_amount_calculated || 0}
                        totalAmount={
                          taxEstimateTotal?.total_amount && taxEstimateTotal?.total_tax_amount_calculated
                            ? Number(taxEstimateTotal.total_amount) +
                              Number(taxEstimateTotal.total_tax_amount_calculated)
                            : 0
                        }
                      />
                    ) : (
                      <Tooltip
                        disabled={isValid || dirty}
                        content={'One address and product item is required to calculate tax'}
                        positioning={{ placement: 'bottom-start' }}
                      >
                        <Button type="submit" loading={isSubmitLoading} disabled={!isValid || !dirty}>
                          Calculate Tax
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                </>
              )}
            </FieldArray>
          </Form>

          {addressFormOpen && (
            <AddressFormModal
              isOpen={addressFormOpen}
              onClose={onCloseAddressModal}
              payload={selectedAddress ?? undefined}
              handleOnSubmit={value => {
                const selectedValue = [...values.addresses];
                const index = selectedValue?.findIndex(a => a.type === value.type);
                if (index !== -1) {
                  selectedValue.splice(index, 1, value);
                } else {
                  selectedValue.push(value);
                }
                setFieldValue('addresses', selectedValue, true);
                handleOnSubmitAddress(value);
                setShowTotalSection(false);
              }}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default CalculatorForm;
