import { AddressStatus, AddressType, CountryCodeEnum } from '../types/shared-types';

export const INITIAL_ADDRESS_DATA = {
  phone: '',
  street_1: '',
  street_2: '',
  county: '',
  state: '',
  city: '',
  postal_code: '',
  country: CountryCodeEnum.US,
  type: AddressType.BILL_TO,
  status: AddressStatus.UNVERIFIED,
};
