import { Box, HStack, Text } from '@chakra-ui/react';
import { formatCurrency } from 'utils/utils';

const CalculatorTotalSection = ({
  subTotal,
  taxAmount,
  totalAmount,
}: {
  subTotal: number;
  taxAmount: number;
  totalAmount: number;
}) => {
  return (
    <Box width={'320px'}>
      <HStack justifyContent={'space-between'} pl={4} pr={14} py={2}>
        <Text>Subtotal</Text>
        <Text>{formatCurrency(subTotal)}</Text>
      </HStack>

      <HStack justifyContent={'space-between'} pl={4} pr={14} py={2}>
        <Text>Tax</Text>
        <Text>{formatCurrency(taxAmount)}</Text>
      </HStack>

      <HStack justifyContent={'space-between'} pl={4} pr={14} py={2} borderRadius={'2px'} bgColor={'#ECF3FE'}>
        <Text fontWeight={'semibold'}>Total</Text>
        <Text fontWeight={'semibold'}>{formatCurrency(totalAmount)}</Text>
      </HStack>
    </Box>
  );
};
export default CalculatorTotalSection;
