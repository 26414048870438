import { SourceEnum } from 'schema/types-schema';
import { AddressValidationSchema } from 'types/address';
import { getMaxLengthMessage } from 'utils/utils';
import { array, InferType, object, string } from 'yup';

export const TaxEstimateValidationSchema = object({
  transaction_items: array()
    .of(
      object().shape({
        external_product_id: string()
          .required('Product is required.')
          .max(100, getMaxLengthMessage('External Product Id', 100)),
        product_description: string().optional().max(1000, getMaxLengthMessage('Product Description', 1000)),
        quantity: string().required('Quantity is required'),
        taxable_amount: string().optional(),
        amount: string().required('Amount is required'),
        date: string().required('Date is required'),
      })
    )
    .required('At least one product is required.'),
  addresses: array()
    .min(1)
    .max(2)
    .required('At least one address is required, Preferred SHIP_TO address.')
    .of(AddressValidationSchema),
});
export type TaxEstimateValidationSchemaType = InferType<typeof TaxEstimateValidationSchema>;

export type TotalDataType = {
  total_amount: number;
  total_tax_amount_calculated: number;
};

export type ProductDropdownItemType = {
  value: string;
  label: string;
  product_description: string | null;
  product_source: SourceEnum;
};
