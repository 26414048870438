import { TransactionEstimateRequest, TransactionEstimateResponse } from '_api-client';
import { AxiosResponse } from 'axios';

import api from './api';

const TAX_ESTIMATE_STATE_KEY = 'tax-estimate';

export const calculateTaxEstimate = (
  orgId: string,
  payload: TransactionEstimateRequest
): Promise<AxiosResponse<TransactionEstimateResponse>> => {
  return api.post(`/v1/tax/estimate`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};
export { TAX_ESTIMATE_STATE_KEY };
