export const stateData = [
  {
    stateId: 'AL',
    state: 'Alabama',
    baseRate: '4.00%',
    avgRate: '9.25%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$250,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'AK',
    state: 'Alaska',
    baseRate: '0.00%',
    avgRate: '1.76%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'No',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'AZ',
    state: 'Arizona',
    baseRate: '5.60%',
    avgRate: '8.37%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'AR',
    state: 'Arkansas',
    baseRate: '6.50%',
    avgRate: '9.46%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'CA',
    state: 'California',
    baseRate: '7.25%',
    avgRate: '8.82%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    dollarThreshold: '$500,000',
    service: 'No',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'CO',
    state: 'Colorado',
    baseRate: '2.90%',
    avgRate: '7.78%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'CT',
    state: 'Connecticut',
    baseRate: '6.35%',
    avgRate: '6.35%',
    localRate: 'No',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'DE',
    state: 'Delaware',
    baseRate: '0.00%',
    avgRate: '0.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'No',
    service: 'No',
    dollarThreshold: 'N/A',
    transactionThreshold: 'N/A',
    physicalNexus: 'No',
  },
  {
    stateId: 'DC',
    state: 'District of Columbia',
    baseRate: '6.00%',
    avgRate: '6.00%',
    localRate: 'No',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'FL',
    state: 'Florida',
    baseRate: '6.00%',
    avgRate: '7.02%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'GA',
    state: 'Georgia',
    baseRate: '4.00%',
    avgRate: '7.40%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'HI',
    state: 'Hawaii',
    baseRate: '4.00%',
    avgRate: '4.44%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Yes',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'ID',
    state: 'Idaho',
    baseRate: '6.00%',
    avgRate: '6.02%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'IL',
    state: 'Illinois',
    baseRate: '6.25%',
    avgRate: '8.82%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'IN',
    state: 'Indiana',
    baseRate: '7.00%',
    avgRate: '7.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'IA',
    state: 'Iowa',
    baseRate: '6.00%',
    avgRate: '6.94%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'KS',
    state: 'Kansas',
    baseRate: '6.50%',
    avgRate: '8.66%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'KY',
    state: 'Kentucky',
    baseRate: '6.00%',
    avgRate: '6.00%',
    localRate: 'No',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'LA',
    state: 'Louisiana',
    baseRate: '4.45%',
    avgRate: '9.55%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'ME',
    state: 'Maine',
    baseRate: '5.50%',
    avgRate: '5.50%',
    localRate: 'No',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MD',
    state: 'Maryland',
    baseRate: '6.00%',
    avgRate: '6.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MA',
    state: 'Massachusetts',
    baseRate: '6.25%',
    avgRate: '6.25%',
    localRate: 'No',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MI',
    state: 'Michigan',
    baseRate: '6.00%',
    avgRate: '6.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MN',
    state: 'Minnesota',
    baseRate: '6.88%',
    avgRate: '7.49%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MS',
    state: 'Mississippi',
    baseRate: '7.00%',
    avgRate: '7.07%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$250,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MO',
    state: 'Missouri',
    baseRate: '4.23%',
    avgRate: '8.33%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'MT',
    state: 'Montana',
    baseRate: '0.00%',
    avgRate: '0.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'No',
    service: 'No',
    dollarThreshold: 'N/A',
    transactionThreshold: 'N/A',
    physicalNexus: 'No',
  },
  {
    stateId: 'NE',
    state: 'Nebraska',
    baseRate: '5.50%',
    avgRate: '6.95%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'NV',
    state: 'Nevada',
    baseRate: '6.85%',
    avgRate: '8.23%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'NH',
    state: 'New Hampshire',
    baseRate: '0.00%',
    avgRate: '0.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'No',
    service: 'No',
    dollarThreshold: 'N/A',
    transactionThreshold: 'N/A',
    physicalNexus: 'No',
  },
  {
    stateId: 'NJ',
    state: 'New Jersey',
    baseRate: '6.63%',
    avgRate: '6.63%',
    localRate: 'No',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'NM',
    state: 'New Mexico',
    baseRate: '5.00%',
    avgRate: '7.72%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Yes',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'NY',
    state: 'New York',
    baseRate: '4.00%',
    avgRate: '8.52%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$500,000',
    transactionThreshold: '100',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'NC',
    state: 'North Carolina',
    baseRate: '4.75%',
    avgRate: '6.99%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'ND',
    state: 'North Dakota',
    baseRate: '5.00%',
    avgRate: '6.97%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'OH',
    state: 'Ohio',
    baseRate: '5.75%',
    avgRate: '7.24%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'OK',
    state: 'Oklahoma',
    baseRate: '4.50%',
    avgRate: '8.98%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'OR',
    state: 'Oregon',
    baseRate: '0.00%',
    avgRate: '0.00%',
    localRate: 'No',
    saas: 'No',
    physical: 'No',
    service: 'No',
    dollarThreshold: 'N/A',
    transactionThreshold: 'N/A',
    physicalNexus: 'No',
  },
  {
    stateId: 'PA',
    state: 'Pennsylvania',
    baseRate: '6.00%',
    avgRate: '6.34%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'PR',
    state: 'Puerto Rico',
    baseRate: '10.50%',
    avgRate: '11.50%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'RI',
    state: 'Rhode Island',
    baseRate: '7.00%',
    avgRate: '7.00%',
    localRate: 'No',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'SC',
    state: 'South Carolina',
    baseRate: '6.00%',
    avgRate: '7.43%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'SD',
    state: 'South Dakota',
    baseRate: '4.50%',
    avgRate: '6.40%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Yes',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'TN',
    state: 'Tennessee',
    baseRate: '7.00%',
    avgRate: '9.55%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'TX',
    state: 'Texas',
    baseRate: '6.25%',
    avgRate: '8.20%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$500,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'UT',
    state: 'Utah',
    baseRate: '6.10%',
    avgRate: '7.19%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'VT',
    state: 'Vermont',
    baseRate: '6.00%',
    avgRate: '6.30%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'VA',
    state: 'Virginia',
    baseRate: '5.30%',
    avgRate: '5.75%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'WA',
    state: 'Washington',
    baseRate: '6.50%',
    avgRate: '8.86%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'WV',
    state: 'West Virginia',
    baseRate: '6.00%',
    avgRate: '6.55%',
    localRate: 'Yes',
    saas: 'Yes',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'WI',
    state: 'Wisconsin',
    baseRate: '5.00%',
    avgRate: '5.43%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: 'N/A',
    physicalNexus: 'Yes',
  },
  {
    stateId: 'WY',
    state: 'Wyoming',
    baseRate: '4.00%',
    avgRate: '5.36%',
    localRate: 'Yes',
    saas: 'No',
    physical: 'Yes',
    service: 'Depends',
    dollarThreshold: '$100,000',
    transactionThreshold: '200',
    physicalNexus: 'Yes',
  },
];
